export const cronosPools = [
  {
    id: 'fairy-unicorn-wcro',
    name: 'UNICORN-WCRO LP🔥',
    token: 'UNICORN-WCRO LP',
    tokenDescription: 'Fairyfinance.Money - UNICORN-WCRO LP AC Auto Burn Vault 🔥',
    tokenAddress: '0x94eE2bEa1262C4B837BeCc7cd562Ceb7A7fFd806',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - UNICORN - WCRO LP',
    earnedTokenAddress: '0x71f21252DD51473781FeE9d0C41aDA6ac4bB0E47',
    earnContractAddress: '0x71f21252DD51473781FeE9d0C41aDA6ac4bB0E47',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'fairy-unicorn-wcro',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Fairyfinance.money',
    assets: ['UNICORN', 'CRO'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://mm.finance/add/0x387c06f6e8d1F65839FCb9171171aBb5F49F8b20/0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
    buyTokenUrl:
      'https://mm.finance/swap?outputCurrency=0x387c06f6e8d1F65839FCb9171171aBb5F49F8b20',
  },
  {
    id: 'fairy-wings-wcro',
    name: 'WINGS-WCRO LP',
    token: 'WINGS-WCRO LP',
    tokenDescription: 'Fairyfinance.Money - WINGS-WCRO LP AC Auto Burn Vault 🔥',
    tokenAddress: '0x7175CA4d5DB20ad01989E6BAf489905B40949d7F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - WINGS - WCRO LP',
    earnedTokenAddress: '0x6fc4D287394135e57c373cf75CE49bAD6FA4391b',
    earnContractAddress: '0x6fc4D287394135e57c373cf75CE49bAD6FA4391b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'fairy-wings-wcro',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Fairyfinance.money',
    assets: ['WINGS', 'CRO'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://mm.finance/add/0xB8Ce964d2fe6b8660A3Cf4c968C0F9CC7f0aFCf1/0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
    buyTokenUrl:
      'https://mm.finance/swap?outputCurrency=0xB8Ce964d2fe6b8660A3Cf4c968C0F9CC7f0aFCf1',
  },
  {
    id: 'gaur-gaur',
    logo: 'single-assets/GAUR.png',
    name: 'GAUR 🔥',
    token: 'GAUR 🔥',
    tokenDescription: 'gaur.money - Single Stake AC Auto Burn Vault🔥',
    tokenAddress: '0x046cb616d7a52173e4Da9efF1BFd590550aa3228',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - GAUR Single Stake',
    earnedTokenAddress: '0xaEF7684b1d5aF45F922dC5AAe937c3a708BF4dbA',
    earnContractAddress: '0xaEF7684b1d5aF45F922dC5AAe937c3a708BF4dbA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'GAUR',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'gaur.money',
    assets: ['GAUR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    buyTokenUrl:
      'https://www.smxswap.com/#/swap?inputCurrency=0xe44Fd7fCb2b1581822D0c862B68222998a0c299a&outputCurrency=0x046cb616d7a52173e4da9eff1bfd590550aa3228',
  },
  {
    id: 'gaur-gaur-eth',
    name: 'GAUR-WETH LP',
    token: 'GAUR-WETH LP ',
    tokenDescription: 'gaur.money - GAUR-WETH LP AC Auto Fee Share Vault',
    tokenAddress: '0xC92837a46B76c5E79d1c228c15d41334b5fEAA97',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Magikfarm - GAUR-WETH LP',
    earnedTokenAddress: '0xfD04602cB488b05B1a3A6C092B22a96f870Fbb6a',
    earnContractAddress: '0xfD04602cB488b05B1a3A6C092B22a96f870Fbb6a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'gaur-gaur-eth',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'GAUR.MONEY',
    assets: ['GAUR', 'WETH'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.smxswap.com/#/add/0xe44Fd7fCb2b1581822D0c862B68222998a0c299a/0x046cb616d7a52173e4da9eff1bfd590550aa3228',
    buyTokenUrl:
      'https://www.smxswap.com/#/swap?inputCurrency=0xe44Fd7fCb2b1581822D0c862B68222998a0c299a&outputCurrency=0x046cb616d7a52173e4da9eff1bfd590550aa3228',
  },
  {
    id: 'darkcrystl-mine-musd',
    name: 'MINE-MUSD LP🔥',
    token: 'MINE-MUSD LP',
    tokenDescription: 'cronos.darkcrystl.com - MINE-MUSD LP AC Auto Fee Share Vault 🔥',
    tokenAddress: '0xB13ae168C893e5bD15f6caAa1D2Da11047e393cA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Magikfarm - MINE-MUSD LP',
    earnedTokenAddress: '0xC4e7A13C0966b572a572a0DA1fe23ddBB3aC3067',
    earnContractAddress: '0xC4e7A13C0966b572a572a0DA1fe23ddBB3aC3067',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'darkcrystl-mine-musd',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Darkcrystl',
    assets: ['MINE', 'MUSD'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://mm.finance/add/0x95aeaf383e2e86a47c11cffde1f7944ecb2c38c2/0x0944d9a8Dea16af1170c51E8DD3921C727A06cBd',
    buyTokenUrl:
      'https://mm.finance/swap?outputCurrency=0x95aeaf383e2e86a47c11cffde1f7944ecb2c38c2&inputCurrency=0x0944d9a8Dea16af1170c51E8DD3921C727A06cBd',
  },
  {
    id: 'darkcrystl-darkcrystl-wcro',
    name: 'DARKCRYSTL-WCRO LP🔥',
    token: 'DARKCRYSTL-WCRO LP',
    tokenDescription: 'cronos.darkcrystl.com - DARKCRYSTL-WCRO LP AC Auto Burn Vault 🔥',
    tokenAddress: '0x59505978Dcdb0c820ECf6486AFEB9b2Baa58Ff49',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Magikfarm - DARKCRYSTL-WCRO LP',
    earnedTokenAddress: '0x0344Eff412334A1Cd978507CF3CF9EB667E8Df1b',
    earnContractAddress: '0x0344Eff412334A1Cd978507CF3CF9EB667E8Df1b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'darkcrystl-darkcrystl-wcro',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Darkcrystl',
    assets: ['DARKCRYSTL', 'CRO'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://mm.finance/add/0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23/0xEfA1FABC2AB6219174aD1c912F56f7de53cDc1E1',
    buyTokenUrl:
      'https://mm.finance/swap?outputCurrency=0xEfA1FABC2AB6219174aD1c912F56f7de53cDc1E1',
  },
  {
    id: 'darkcrystl-mine-wcro',
    name: 'MINE-WCRO LP🔥',
    token: 'MINE-WCRO LP',
    tokenDescription: 'cronos.darkcrystl.com - MINE-WCRO LP AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x1AA703ecBb80271A0d719E4405D030F0fc46c30E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Magikfarm - MINE-WCRO LP',
    earnedTokenAddress: '0x0ba923bf767D09817EBcD17d599A4759d79F1966',
    earnContractAddress: '0x0ba923bf767D09817EBcD17d599A4759d79F1966',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'darkcrystl-mine-wcro',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Darkcrystl',
    assets: ['MINE', 'CRO'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://mm.finance/add/0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23/0x0944d9a8Dea16af1170c51E8DD3921C727A06cBd',
    buyTokenUrl:
      'https://mm.finance/swap?outputCurrency=0x0944d9a8Dea16af1170c51E8DD3921C727A06cBd',
  },
  {
    id: 'darkcrystl-darkcrystl-mine',
    name: 'DARKCRYSTL-MINE LP🔥',
    token: 'DARKCRYSTL-MINE LP',
    tokenDescription: 'cronos.darkcrystl.com - DARKCRYSTL-MINE LP AC Auto Fee Share Vault 🔥',
    tokenAddress: '0xbe4C7893e79028f4956307d2efD2cf8230360c70',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Magikfarm -DARKCRYSTL-MINE LP',
    earnedTokenAddress: '0x515bAF9CbB69A7E9188C526c809f504d87C6C085',
    earnContractAddress: '0x515bAF9CbB69A7E9188C526c809f504d87C6C085',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'darkcrystl-darkcrystl-mine',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Darkcrystl',
    assets: ['DARKCRYSTL', 'MINE'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://mm.finance/add/0xEfA1FABC2AB6219174aD1c912F56f7de53cDc1E1/0x0944d9a8Dea16af1170c51E8DD3921C727A06cBd',
    buyTokenUrl:
      'https://mm.finance/swap?outputCurrency=0xEfA1FABC2AB6219174aD1c912F56f7de53cDc1E1&inputCurrency=0x0944d9a8Dea16af1170c51E8DD3921C727A06cBd',
  },
];
