import { govPoolABI } from '../abi';

export const moonriverStakePools = [
  {
    id: 'bifi-moonriver',
    name: 'BIFI',
    logo: 'single-assets/BIFI.png',
    token: 'BIFI',
    tokenDecimals: 18,
    tokenAddress: '0x173fd7434B8B50dF08e3298f173487ebDB35FD14',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'MOVR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x98878B06940aE243284CA214f92Bb71a2b032B8A',
    earnContractAddress: '0x4Aabd0d73181325DD1609Ce696eF048702DE7153',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'WMOVR',
    partnership: false,
    status: 'active',
    fixedStatus: true,
    partners: [
      {
        logo: 'stake/beefy/beefyfinance.png',
        logoNight: 'stake/beefy/beefyfinance_night.png',
        background: 'stake/beefy/background.png',
        text: "BombFarm is The Multi-Chain Yield Optimizer across many sidechains, enabling users to earn autocompounded yield on their crypto. Did you know also that you can own a piece of Beefy itself? Beefy runs on its governance token, BIFI. The token has a set supply of 80,000 across all chains; no more may be minted, ever! As a holder of BIFI you may create and vote on important DAO proposals, and you become dividend-eligible to earn a share of every compounding harvest on Beefy vaults, hour by hour. Here on Moonriver, you just need to stake BIFI in this reward pool, or in the autocompounding BIFI Maxi vault on the main page. For this pool, MOVR dividends are gathered and sent proportionally to each staker. Stake here, return later to claim the MOVR you've earned.",
        website: 'https://www.bomb.farm',
        social: {
          telegram: 'http://t.me/beefyfinance',
          twitter: 'https://twitter.com/beefyfinance',
        },
      },
    ],
  },

  {
    id: 'moo_finn-wmovr-finn-huckleberry',
    name: 'Huckleberry',
    assets: ['FINN', 'MOVR'],
    token: 'mooFinnMOVR-FINN',
    tokenDecimals: 18,
    tokenAddress: '0xFaA15A1B4DAB85d35e912f88127b9986638a1265',
    tokenOracle: 'lps',
    tokenOracleId: 'finn-wmovr-finn',
    earnedToken: 'FINN',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
    earnContractAddress: '0x405EE7F4f067604b787346bC22ACb66b06b15A4B',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'FINN',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1639217938,
    partners: [
      {
        logo: 'stake/huckleberry/logo.png',
        background: 'stake/huckleberry/bg.png',
        text: 'Huckleberry is a community driven AMM crosschain DEX built on Moonriver, where every single feature is designed around creating value for FINN holders. This is seen in everything from its high-APR Rivers, risk-free Excursions and the FINN token itself. FINN has a special feature called Passive Sailin’, where 1% of every FINN transaction is shared among all FINN holders. This means that all our users are constantly earning just by holding FINN.',
        website: 'https://www.huckleberry.finance/',
        social: {
          telegram: 'https://t.me/HuckleberryDex',
          twitter: 'https://twitter.com/HuckleberryDEX',
        },
      },
    ],
  },

  {
    id: 'moo_solarbeam-frax-wmovr-2-huckleberry',
    name: 'Huckleberry',
    assets: ['FRAX', 'MOVR'],
    token: 'mooSolarFRAX-WMOVR',
    tokenDecimals: 18,
    tokenAddress: '0xf8e9774AE3cc7aE3eE11161cC1313252A0784E18',
    tokenOracle: 'lps',
    tokenOracleId: 'solarbeam-frax-wmovr-2',
    earnedToken: 'FINN',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
    earnContractAddress: '0x3C9C884eFAB85c44D675039de227b3Dd275c360e',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'FINN',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1638526810,
    partners: [
      {
        logo: 'stake/huckleberry/logo.png',
        background: 'stake/huckleberry/bg.png',
        text: 'Huckleberry is a community driven AMM crosschain DEX built on Moonriver, where every single feature is designed around creating value for FINN holders. This is seen in everything from its high-APR Rivers, risk-free Excursions and the FINN token itself. FINN has a special feature called Passive Sailin’, where 1% of every FINN transaction is shared among all FINN holders. This means that all our users are constantly earning just by holding FINN.',
        website: 'https://www.huckleberry.finance/',
        social: {
          telegram: 'https://t.me/HuckleberryDex',
          twitter: 'https://twitter.com/HuckleberryDEX',
        },
      },
    ],
  },
];
