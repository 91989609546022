export const auroraPools = [
  {
    id: 'tri-usdt-tri',
    name: 'TRI-USDT LP',
    token: 'TRI-USDT LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0x61C9E05d1Cdb1b70856c7a2c53fA9c220830633c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm - TRI-USDT',
    earnedTokenAddress: '0x39ce220ad9EF91433B2d0D0d8291AFf9927EE13B',
    earnContractAddress: '0x39ce220ad9EF91433B2d0D0d8291AFf9927EE13B',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tri-usdt-tri',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['TRI', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0xFa94348467f64D5A457F75F8bc40495D33c65aBB',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0xFa94348467f64D5A457F75F8bc40495D33c65aBB/0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
    createdAt: 1651846365,
  },
  {
    id: 'tri-aurora-tri',
    name: 'TRI-AURORA LP',
    token: 'TRI-AURORA LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0xd1654a7713617d41A8C9530Fb9B948d00e162194',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm - TRI-AURORA',
    earnedTokenAddress: '0x88d94812CFFBeE45e726b582797F1D9E9667fedb',
    earnContractAddress: '0x88d94812CFFBeE45e726b582797F1D9E9667fedb',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tri-aurora-tri',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['TRI', 'AURORA'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0xFa94348467f64D5A457F75F8bc40495D33c65aBB',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0xFa94348467f64D5A457F75F8bc40495D33c65aBB/0x8BEc47865aDe3B172A928df8f990Bc7f2A3b9f79',
    createdAt: 1651846365,
  },
  {
    id: 'trisolaris-usdt-usdc',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0x2fe064B6c7D274082aa5d2624709bC9AE7D16C77',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm - USDC-USDT',
    earnedTokenAddress: '0x6F2066146Cf991157590DA255b77A1dC1b8B82ff',
    earnContractAddress: '0x6F2066146Cf991157590DA255b77A1dC1b8B82ff',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'trisolaris-usdt-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0xB12BFcA5A55806AaF64E99521918A4bf0fC40802/0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
    createdAt: 1651846365,
  },
  {
    id: 'trisolaris-near-wbtc',
    name: 'NEAR-WBTC LP',
    token: 'NEAR-WBTC LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0xbc8A244e8fb683ec1Fd6f88F3cc6E565082174Eb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm - NEAR-WBTC',
    earnedTokenAddress: '0xd50BFF5beA2D5eD45124A7218B4108b29325FE40',
    earnContractAddress: '0xd50BFF5beA2D5eD45124A7218B4108b29325FE40',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'trisolaris-near-wbtc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['NEAR', 'WBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0xF4eB217Ba2454613b15dBdea6e5f22276410e89e/0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    createdAt: 1651846365,
  },
  {
    id: 'trisolaris-near-weth',
    name: 'NEAR-WETH LP',
    token: 'NEAR-WETH LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0x63da4DB6Ef4e7C62168aB03982399F9588fCd198',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm - NEAR-WETH',
    earnedTokenAddress: '0x2F984CbdD35Fa0Dfb4889a5ed11ea669D07A2400',
    earnContractAddress: '0x2F984CbdD35Fa0Dfb4889a5ed11ea669D07A2400',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'trisolaris-near-weth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['NEAR', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0xB12BFcA5A55806AaF64E99521918A4bf0fC40802/0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    createdAt: 1651846365,
  },
  {
    id: 'tri-usdc-near',
    name: 'NEAR-USDC LP',
    token: 'NEAR-USDC LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0x20F8AeFB5697B77E0BB835A8518BE70775cdA1b0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm - NEAR-USDC',
    earnedTokenAddress: '0x6B0F60C16108fb809dC1E11399bb2018F93E1759',
    earnContractAddress: '0x6B0F60C16108fb809dC1E11399bb2018F93E1759',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tri-usdc-near',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['NEAR', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0xB12BFcA5A55806AaF64E99521918A4bf0fC40802/0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    createdAt: 1651846365,
  },
  {
    id: 'tri-usdt-near',
    name: 'NEAR-USDT LP',
    token: 'NEAR-USDT LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0x03B666f3488a7992b2385B12dF7f35156d7b29cD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm - NEAR-USDT',
    earnedTokenAddress: '0xb4A893a42c9559240D255cB1720e9a183aD289A5',
    earnContractAddress: '0xb4A893a42c9559240D255cB1720e9a183aD289A5',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tri-usdt-near',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['NEAR', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0x4988a896b1227218e4A686fdE5EabdcAbd91571f/0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    createdAt: 1651846365,
  },
  {
    id: 'tri-bstn-near',
    name: 'NEAR-BSTN LP',
    token: 'NEAR-BSTN LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0xBBf3D4281F10E537d5b13CA80bE22362310b2bf9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - NEAR-BSTN',
    earnedTokenAddress: '0x64839E8CF15587a6A2425e366C0596d625e403F8',
    earnContractAddress: '0x64839E8CF15587a6A2425e366C0596d625e403F8',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tri-bstn-near',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['BSTN', 'NEAR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0x9f1F933C660a1DC856F0E0Fe058435879c5CCEf0',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0x9f1F933C660a1DC856F0E0Fe058435879c5CCEf0/0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    createdAt: 1651846365,
  },
  {
    id: 'tri-stnear-near',
    name: 'NEAR-STNEAR LP',
    token: 'NEAR-STNEAR LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0x47924Ae4968832984F4091EEC537dfF5c38948a4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm - NEAR-stNEAR',
    earnedTokenAddress: '0x99c6D8141249413C8876c37df16F27448A80Bb03',
    earnContractAddress: '0x99c6D8141249413C8876c37df16F27448A80Bb03',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tri-stnear-near',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['STNEAR', 'NEAR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0x07F9F7f963C5cD2BBFFd30CcfB964Be114332E30',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d/0x07F9F7f963C5cD2BBFFd30CcfB964Be114332E30',
    createdAt: 1651846365,
  },
  {
    id: 'tri-near-flx',
    name: 'NEAR-FLX LP',
    token: 'NEAR-FLX LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0x48887cEEA1b8AD328d5254BeF774Be91B90FaA09',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - NEAR-FLX',
    earnedTokenAddress: '0x364A28F0A9E5B74A2330379b397eE0175F04aAde',
    earnContractAddress: '0x364A28F0A9E5B74A2330379b397eE0175F04aAde',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tri-near-flx',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['NEAR', 'FLX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0xea62791aa682d455614eaA2A12Ba3d9A2fD197af/0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    createdAt: 1651846365,
  },
  {
    id: 'tri-aurora-near',
    name: 'AURORA-NEAR LP',
    token: 'AURORA-NEAR LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0x1e0e812FBcd3EB75D8562AD6F310Ed94D258D008',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm AURORA-NEAR',
    earnedTokenAddress: '0x043dDffC1B5C5A8A79f69a437d8dF11163fF34da',
    earnContractAddress: '0x043dDffC1B5C5A8A79f69a437d8dF11163fF34da',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tri-aurora-near',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['AURORA', 'NEAR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0x8BEc47865aDe3B172A928df8f990Bc7f2A3b9f79',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0x8BEc47865aDe3B172A928df8f990Bc7f2A3b9f79/0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    createdAt: 1651702395,
  },
  {
    id: 'tri-aurora-weth',
    name: 'AURORA-WETH LP',
    token: 'AURORA-WETH LP',
    tokenDescription: 'Trisolaris',
    tokenAddress: '0x5eeC60F348cB1D661E4A5122CF4638c7DB7A886e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm AURORA-WETH',
    earnedTokenAddress: '0xa2D5d9719cAae8506EFb3BE48101dDFF7d9E9056',
    earnContractAddress: '0xa2D5d9719cAae8506EFb3BE48101dDFF7d9E9056',
    pricePerFullShare: 1,
    withdrawalFee: '0.1%',
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tri-aurora-weth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trisolaris',
    assets: ['AURORA', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?outputCurrency=0x8BEc47865aDe3B172A928df8f990Bc7f2A3b9f79',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB/0x8BEc47865aDe3B172A928df8f990Bc7f2A3b9f79',
    createdAt: 1651702395,
  },
  {
    id: 'carbon-carbon-near',
    name: 'CARBON-NEAR LP 🔥',
    token: 'CARBON-NEAR LP 🔥',
    tokenDescription: 'carbon-fi.net - CARBON-NEAR LP AC Auto Burn Vault 🔥',
    tokenAddress: '0x98Cf148cdC3E6d546D30717cE034C365ba204A4F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - CARBON-NEAR LP',
    earnedTokenAddress: '0xf81176796308D1154bb6Ed3D0Ca8e07a1E283600',
    earnContractAddress: '0xf81176796308D1154bb6Ed3D0Ca8e07a1E283600',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'carbon-carbon-near',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Carbon',
    assets: ['CARBON', 'NEAR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    buyTokenUrl:
      'https://www.trisolaris.io/#/swap?inputCurrency=0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d&outputCurrency=0x33ADf08ae486bf89ABc9634f6623A997FFDB66e7',
    addLiquidityUrl:
      'https://www.trisolaris.io/#/add/0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d/0x33ADf08ae486bf89ABc9634f6623A997FFDB66e7',
  },
];
