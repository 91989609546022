export const bscPools = [
  {
    id: 'truth-magik-busd',
    name: 'MAGIK-BUSD LP 🔥',
    token: 'MAGIK-BUSD LP🔥',
    tokenDescription: 'commandswap.com - MAGIK - BUSD LP - AC Vault - TRUTH',
    tokenAddress: '0xc802a32060ffd229A71321493b6374Bd9Ffd46f7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-MAGIK-BUSD',
    earnedTokenAddress: '0x794F60faC87121A06a17a77d8E13c04E3155d55b',
    earnContractAddress: '0x794F60faC87121A06a17a77d8E13c04E3155d55b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'truth-magik-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CommandSwap',
    assets: ['MAGIK', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://commandswap.com/liquidity/add',
    buyTokenUrl: 'https://commandswap.com/',
    createdAt: 1648773316,
  },
  {
    id: 'empv2-emp-eth',
    name: 'EMP-ETH LP 🔥',
    token: 'EMP-ETH LP🔥',
    tokenDescription: 'EMP.Money - EMP - ETH LP - AC Auto Burn Vault 🔥',
    tokenAddress: '0x84821bb588f049913Dc579Dc511E5e31EB22d5E4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-EMP-ETH-V2',
    earnedTokenAddress: '0x285114354c5554AAb24e0d63992211142FfE5E6c',
    earnContractAddress: '0x285114354c5554AAb24e0d63992211142FfE5E6c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'empv2-emp-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'EMP.Money',
    assets: ['EMP', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
    createdAt: 1648773316,
  },
  {
    id: 'emp-emp-eth',
    name: 'EMP-ETH LP 🔥',
    token: 'EMP-ETH LP 🔥',
    tokenDescription: 'EMP.Money - EMP - ETH LP - AC Auto Burn Vault 🔥',
    tokenAddress: '0x84821bb588f049913Dc579Dc511E5e31EB22d5E4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-EMP-ETH',
    earnedTokenAddress: '0x13eb43c8289CC8D7945462FF4fAfe686d4Bf53F6',
    earnContractAddress: '0x13eb43c8289CC8D7945462FF4fAfe686d4Bf53F6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'emp-emp-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'EMP.Money',
    assets: ['EMP', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
    createdAt: 1648773316,
  },
  {
    id: 'emp-eshare-mdb+',
    name: 'ESHARE-MDB+ LP',
    token: 'ESHARE-MDB+ LP',
    tokenDescription: 'EMP.Money - ESHARE - MDB+ LP - AC Fee Share Vault',
    tokenAddress: '0x51586182A2031f3e384D080f78C8836D48eBBfFC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-ESHARE-MDB+',
    earnedTokenAddress: '0xA8530643faD58BB82378Fd804e10530D39d5d560',
    earnContractAddress: '0xA8530643faD58BB82378Fd804e10530D39d5d560',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'emp-eshare-mdb+',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'EMP.Money',
    assets: ['ESHARE', 'MDB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0xDB20F6A8665432CE895D724b417f77EcAC956550/0x9f8BB16f49393eeA4331A39B69071759e54e16ea',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0x9f8BB16f49393eeA4331A39B69071759e54e16ea&outputCurrency=0xDB20F6A8665432CE895D724b417f77EcAC956550',
    createdAt: 1648773315,
  },
  {
    id: 'emp-eshare-wbnb',
    name: 'ESHARE-BNB LP',
    token: 'ESHARE-BNB LP',
    tokenDescription: 'EMP.Money - ESHARE - BNB LP - AC Fee Share Vault',
    tokenAddress: '0x1747AF98EBF0B22d500014c7dd52985d736337d2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-ESHARE-BNB',
    earnedTokenAddress: '0xc822dE2843cf3a8a0642908F13d9f57E7A6D9011',
    earnContractAddress: '0xc822dE2843cf3a8a0642908F13d9f57E7A6D9011',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'emp-eshare-wbnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'EMP.Money',
    assets: ['ESHARE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0xDB20F6A8665432CE895D724b417f77EcAC956550/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0xDB20F6A8665432CE895D724b417f77EcAC956550',
    createdAt: 1648773315,
  },
];
