export const dogePools = [
  {
    id: 'dogium-dogium-usdc',
    name: 'DOGIUM-USDC LP',
    token: 'DOGIUM-USDC LP',
    tokenDescription: 'lithium.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x6E08Bcb7c2289E6Aa0BD17d0dDED2D788ab2e8D5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - LD-DOGIUM-USDC-LP',
    earnedTokenAddress: '0xB5E8BB16a8cc347d3671Cb7d855a7776e34c9E35',
    earnContractAddress: '0xB5E8BB16a8cc347d3671Cb7d855a7776e34c9E35',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dogium-dogium-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Dogium',
    assets: ['DOGIUM', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://lithium.dog/#/farms',
    buyTokenUrl: 'https://lithium.dog/#/farms',
  },
  {
    id: 'dogium-dogium-wwdoge',
    name: 'DOGIUM-WDOGE LP',
    token: 'DOGIUM-WDOGE LP',
    tokenDescription: 'lithium.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x3ee4617304e8b973fb8bd9dfd19e027c86ce4b16',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - LD-DOGIUM-WDOGE-LP',
    earnedTokenAddress: '0xc6923EAE4a5E1270F6066Cb282615AB7e3e9dEEE',
    earnContractAddress: '0xc6923EAE4a5E1270F6066Cb282615AB7e3e9dEEE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dogium-dogium-wwdoge',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Dogium',
    assets: ['DOGIUM', 'WWDOGE'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://lithium.dog/#/farms',
    buyTokenUrl: 'https://lithium.dog/#/farms',
  },
  {
    id: 'dogium-usdt-wwdoge',
    name: 'USDT-WDOGE LP',
    token: 'USDT-WDOGE LP',
    tokenDescription: 'lithium.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x6c0f9cbb5958831c9290fee5349c749421024825',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - LD-USDT-WDOGE-LP',
    earnedTokenAddress: '0xccB0e83cEa572CA766FFb57BF69d09D452F659Db',
    earnContractAddress: '0xccB0e83cEa572CA766FFb57BF69d09D452F659Db',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dogium-usdt-wwdoge',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Dogium',
    assets: ['DOGIUM', 'WWDOGE'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://lithium.dog/#/farms',
    buyTokenUrl: 'https://lithium.dog/#/farms',
  },
  {
    id: 'dogium-usdc-wwdoge',
    name: 'USDC-WDOGE LP',
    token: 'USDC-WDOGE LP',
    tokenDescription: 'lithium.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x8DCeBE9f071562D52b5ABB17235f3bCA768C1e44',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - LD-USDC-WDOGE-LP',
    earnedTokenAddress: '0xA53D157C4957f59Afbb189cd645B9C7eF5561EB7',
    earnContractAddress: '0xA53D157C4957f59Afbb189cd645B9C7eF5561EB7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dogium-usdc-wwdoge',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Dogium',
    assets: ['USDC', 'WWDOGE'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://lithium.dog/#/farms',
    buyTokenUrl: 'https://lithium.dog/#/farms',
  },
  {
    id: 'dogium-usdc-usdt',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT LP',
    tokenDescription: 'lithium.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0xD95b66FBD36C5754FE7C837500cccBf113fD6ac6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - LD-USDC-USDT-LP',
    earnedTokenAddress: '0x79996f1080c1DFA4429E936134993983AAE308C3',
    earnContractAddress: '0x79996f1080c1DFA4429E936134993983AAE308C3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dogium-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Dogium',
    assets: ['USDC', 'USDT'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://lithium.dog/#/farms',
    buyTokenUrl: 'https://lithium.dog/#/farms',
  },
  {
    id: 'dogium-weth-usdc',
    name: 'ETH-USDC LP',
    token: 'ETH-USDC LP',
    tokenDescription: 'lithium.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x7a3542fADA818C705E1Ce6dA508F5aa5dfC35edd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - LD-ETH-USDC-LP',
    earnedTokenAddress: '0xa64aBA2Ed2E6dDb861f901329420e04F6493DED4',
    earnContractAddress: '0xa64aBA2Ed2E6dDb861f901329420e04F6493DED4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dogium-weth-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Dogium',
    assets: ['ETH', 'USDT'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://lithium.dog/#/farms',
    buyTokenUrl: 'https://lithium.dog/#/farms',
  },
  {
    id: 'dogium-usdt-weth',
    name: 'ETH-USDT LP',
    token: 'ETH-USDT LP',
    tokenDescription: 'lithium.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0xbeb9d5581667936568446BCB2b27f4e49c95525B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - LD-ETH-USDC-LP',
    earnedTokenAddress: '0xD49cC3b7Ef43B7baF149150C5d923C777Eb269Ea',
    earnContractAddress: '0xD49cC3b7Ef43B7baF149150C5d923C777Eb269Ea',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dogium-usdt-weth',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Dogium',
    assets: ['ETH', 'USDT'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://lithium.dog/#/farms',
    buyTokenUrl: 'https://lithium.dog/#/farms',
  },
  {
    id: 'yodeswap-wbtc-usdc',
    name: 'WBTC-USDC LP',
    token: 'WBTC-USDC LP',
    tokenDescription: 'yodeswap.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x5aC5529f6d948d565A7832B0950592845B029584',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - YS-WBTC-USDC-LP',
    earnedTokenAddress: '0x12AefAa0c1961d450D5772a5e94D721A9eBAF72c',
    earnContractAddress: '0x12AefAa0c1961d450D5772a5e94D721A9eBAF72c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yodeswap-wbtc-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Yodeswap',
    assets: ['WBTC', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.yodeswap.dog/exchange/add/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f/0x765277EebeCA2e31912C9946eAe1021199B39C61',
    buyTokenUrl:
      'https://app.yodeswap.dog/exchange/swap/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f/0x765277EebeCA2e31912C9946eAe1021199B39C61',
  },
  {
    id: 'yodeswap-eth-usdc',
    name: 'ETH-USDC LP',
    token: 'ETH-USDC LP',
    tokenDescription: 'yodeswap.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x7a3542fADA818C705E1Ce6dA508F5aa5dfC35edd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - YS-ETH-USDC-LP',
    earnedTokenAddress: '0xf27d4330036B208Ad09c478410D1439C0c040658',
    earnContractAddress: '0xf27d4330036B208Ad09c478410D1439C0c040658',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yodeswap-eth-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Yodeswap',
    assets: ['ETH', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://yodeswap.dog/exchange/add/0xfb75965be47e030f1a2e04fa2b6b7E3D92ef9546/0x765277EebeCA2e31912C9946eAe1021199B39C61',
    buyTokenUrl:
      'https://app.yodeswap.dog/exchange/swap?inputCurrency=0xfb75965be47e030f1a2e04fa2b6b7E3D92ef9546&outputCurrency=0x765277EebeCA2e31912C9946eAe1021199B39C61',
  },
  {
    id: 'yodeswap-yode-wwdoge',
    name: 'Yode-WWDOGE LP',
    token: 'Yode-WWDOGE LP',
    tokenDescription: 'yodeswap.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x2Dd53abcFEBE71a9Cd5dFF4f44458E14707F9280',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - YODE-WWDOGE-LP',
    earnedTokenAddress: '0xe8cE63e37bB4B662837144058c298C4e7Fd0b93C',
    earnContractAddress: '0xe8cE63e37bB4B662837144058c298C4e7Fd0b93C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yodeswap-yode-wwdoge',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Yodeswap',
    assets: ['YODE', 'WWDOGE'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://yodeswap.dog/exchange/add/WDOGE/0x6FC4563460d5f45932C473334d5c1C5B4aEA0E01',
    buyTokenUrl:
      'https://app.yodeswap.dog/exchange/swap?inputCurrency=0xb7ddc6414bf4f5515b52d8bdd69973ae205ff101&outputCurrency=0x6FC4563460d5f45932C473334d5c1C5B4aEA0E01',
  },
  {
    id: 'yodeswap-yode-usdc',
    name: 'Yode-USDC LP',
    token: 'Yode-USDC LP',
    tokenDescription: 'yodeswap.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x0a5a84AdEE386d295326D93403A92a128130F944',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - YODE-USDC-LP',
    earnedTokenAddress: '0xDa47eF56d2Fc745eB7f1Dc5A438171C1F4C4D6d6',
    earnContractAddress: '0xDa47eF56d2Fc745eB7f1Dc5A438171C1F4C4D6d6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yodeswap-yode-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Yodeswap',
    assets: ['YODE', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://yodeswap.dog/exchange/add/0x765277EebeCA2e31912C9946eAe1021199B39C61/0x6FC4563460d5f45932C473334d5c1C5B4aEA0E01',
    buyTokenUrl:
      'https://app.yodeswap.dog/exchange/swap?inputCurrency=0x765277EebeCA2e31912C9946eAe1021199B39C61&outputCurrency=0x6FC4563460d5f45932C473334d5c1C5B4aEA0E01',
  },
  {
    id: 'yodeswap-usdc-wwdoge',
    name: 'WWDOGE-USDC LP',
    token: 'WWDOGE-USDC LP',
    tokenDescription: 'yodeswap.dog - AC Auto Fee Share Vault 🔥',
    tokenAddress: '0x8DCeBE9f071562D52b5ABB17235f3bCA768C1e44',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - WWDOGE-USDC-LP',
    earnedTokenAddress: '0x9Cb1f2152EaeF5D5Af03165eDe32c3282993D127',
    earnContractAddress: '0x9Cb1f2152EaeF5D5Af03165eDe32c3282993D127',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yodeswap-usdc-wwdoge',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'Yodeswap',
    assets: ['WWDOGE', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://yodeswap.dog/exchange/add/WDOGE/0x765277EebeCA2e31912C9946eAe1021199B39C61',
    buyTokenUrl:
      'https://app.yodeswap.dog/exchange/swap/WDOGE/&outputCurrency=0x765277EebeCA2e31912C9946eAe1021199B39C61',
  },
];
