export const fantomPools = [
  {
    id: 'equalizer-magik-ftm',
    name: 'MAGIK-FTM LP Equalizer Farm',
    token: 'MAGIK-FTM LP Equalizer Farm',
    tokenDescription: 'Equalizer.exchange LP - AC Vault',
    tokenAddress: '0x7e46D5F4Add361bb52b5a933b0e004E924170ba0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-MAGIK-WFTM-EQUALIZER',
    earnedTokenAddress: '0x52fCEB46d4C50da23F85373118ADc9891dFffbc8',
    earnContractAddress: '0x52fCEB46d4C50da23F85373118ADc9891dFffbc8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-magik-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'equalizer.exchange',
    assets: ['MAGIK', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://equalizer.exchange/liquidity/',
    buyTokenUrl: 'https://equalizer.exchange/swap',
  },
  {
    id: 'equalizer-might-usdc',
    name: 'MIGHT-USDC LP Equalizer Farm',
    token: 'MIGHT-USDC LP Equalizer Farm',
    tokenDescription: 'Equalizer.exchange LP - AC Vault',
    tokenAddress: '0x476606b47dDA7131268A25F144e6F5F529C2d918',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-MIGHT-USDC LP-EQUALIZER',
    earnedTokenAddress: '0xEB36B4524c547e52D0bfb832744e9CA6eEecdEb7',
    earnContractAddress: '0xEB36B4524c547e52D0bfb832744e9CA6eEecdEb7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-might-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'equalizer.exchange',
    assets: ['MIGHT', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://equalizer.exchange/liquidity/',
    buyTokenUrl: 'https://equalizer.exchange/swap',
  },
  {
    id: 'equalizer-wftm-conk',
    name: 'CONK-WFTM LP Equalizer Farm',
    token: 'CONK-WFTM LP Equalizer Farm',
    tokenDescription: 'Equalizer.exchange LP - conk-USDC LP AC Vault',
    tokenAddress: '0x4855C81908e60F33Fc60540D2894Cc11143BCb13',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-CONK-WFTM',
    earnedTokenAddress: '0xE2d6CAf185321756F9Da12C9aB9008D8A01F963D',
    earnContractAddress: '0xE2d6CAf185321756F9Da12C9aB9008D8A01F963D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-wftm-conk',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'equalizer.exchange',
    assets: ['CONK', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://equalizer.exchange/liquidity/',
    buyTokenUrl: 'https://equalizer.exchange/swap',
  },
  {
    id: 'equalizer-usdc-wftm',
    name: 'USDC-WFTM LP Equalizer Farm',
    token: 'USDC-WFTM LP Equalizer Farm',
    tokenDescription: 'Equalizer.exchange LP - USDC-WFTM AC Vault',
    tokenAddress: '0x7547d05dFf1DA6B4A2eBB3f0833aFE3C62ABD9a1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-USDC-WFTM-EQUALIZER',
    earnedTokenAddress: '0x284D6c55bcF3Ad0F7A9731fDADc9F181DBAaA029',
    earnContractAddress: '0x284D6c55bcF3Ad0F7A9731fDADc9F181DBAaA029',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-usdc-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'equalizer.exchange',
    assets: ['USDC', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://equalizer.exchange/liquidity/',
    buyTokenUrl: 'https://equalizer.exchange/swap',
  },
  {
    id: 'equalizer-usdc-eth',
    name: 'ETH-USDC LP Equalizer Farm',
    token: 'ETH-USDC LP Equalizer Farm',
    tokenDescription: 'Equalizer.exchange LP - ETH-USDC AC Vault',
    tokenAddress: '0x515d84c494454835443B303a914a341e80f67278',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-ETH-USDC-',
    earnedTokenAddress: '0xD498399baf4FB7868684E906B2066e9e7C2f5bf8',
    earnContractAddress: '0xD498399baf4FB7868684E906B2066e9e7C2f5bf8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-usdc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'equalizer.exchange',
    assets: ['ETH', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://equalizer.exchange/liquidity/',
    buyTokenUrl: 'https://equalizer.exchange/swap',
  },
  {
    id: 'equalizer-wftm-eth',
    name: 'WETH-WFTM LP Equalizer Farm',
    token: 'WETH-WFTM LP Equalizer Farm',
    tokenDescription: 'Equalizer.exchange LP - WETH-WFTM AC Vault',
    tokenAddress: '0xE3bD349BDb8203C15426b2d273f57568E658F843',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-WETH-WFTM-EQUALIZER',
    earnedTokenAddress: '0xb754700CE754a85BFF76363c7766EC34cAf7dbd4',
    earnContractAddress: '0xb754700CE754a85BFF76363c7766EC34cAf7dbd4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-wftm-eth',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'equalizer.exchange',
    assets: ['ETH', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://equalizer.exchange/liquidity/',
    buyTokenUrl: 'https://equalizer.exchange/swap',
  },
  {
    id: 'equalizer-ring-usdc',
    name: 'RING-USDC LP Equalizer Farm',
    token: 'RING-USDC LP Equalizer Farm',
    tokenDescription: 'Equalizer.exchange LP - RING-USDC LP AC Vault',
    tokenAddress: '0x8418BAb773ABF430907b39B7dB6A6F8318A9210e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-RING-USDC-EQUALIZER',
    earnedTokenAddress: '0xe1E035B6efe9aCA3A46AeEDe3E3c5202fAD26f15',
    earnContractAddress: '0xe1E035B6efe9aCA3A46AeEDe3E3c5202fAD26f15',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-ring-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'equalizer.exchange',
    assets: ['RING', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://equalizer.exchange/liquidity/',
    buyTokenUrl: 'https://equalizer.exchange/swap',
  },
  // {
  //   id: 'equalizer-wftm-deus',
  //   name: 'MAGIK-FTM LP Equalizer',
  //   token: 'MAGIK-FTM LP Equalizer',
  //   tokenDescription: 'MagikSwap LP - AC Auto Burn Vault 🔥',
  //   tokenAddress: '0xFfE9C27F0a12dABe5c5Bd46D144a5c0140725566',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'MagikFarm-MAGIK-WFTM-EQUALIZER',
  //   earnedTokenAddress: '0x52fCEB46d4C50da23F85373118ADc9891dFffbc8',
  //   earnContractAddress: '0x52fCEB46d4C50da23F85373118ADc9891dFffbc8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'equalizer-wftm-deus',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   partnership: true,
  //   isMooStaked: true,
  //   status: 'active',
  //   platform: 'MagikSwap',
  //   assets: ['MAGIK', 'FTM'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratLP',
  //   addLiquidityUrl: 'https://magikswap.dog/liquidity',
  //   buyTokenUrl: 'https://magikswap.dog/swap',
  // },
  {
    id: 'magikswap-magik',
    logo: 'single-assets/MAGIK.png',
    name: 'MAGIK Burn Pit🔥',
    token: 'MAGIK Burn Pit🔥',
    tokenDescription: 'Magik.Finance - Single Stake AC Burn Pit Vault🔥',
    tokenAddress: '0x87a5c9b60a3aaf1064006fe64285018e50e0d020',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'magikfarmMAGIK',
    earnedTokenAddress: '0x0365b8Aa14D5Eecea962C28e4531D1bc2a22D612',
    earnContractAddress: '0x0365b8Aa14D5Eecea962C28e4531D1bc2a22D612',
    pricePerFullShare: 1,
    withdrawalFee: '4%',
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'MAGIK',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'MagikSwap',
    assets: ['MAGIK'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x87a5c9b60a3aaf1064006fe64285018e50e0d020',
  },
  {
    id: 'magikswap-xmshare',
    logo: 'single-assets/xMSHARE.png',
    name: 'xMSHARE Burn Pit🔥',
    token: 'xMSHARE Burn Pit🔥',
    tokenDescription: 'Magik.Finance - xMSHARE Single Stake AC Burn Pit Vault🔥',
    tokenAddress: '0xb659f6C8C4f1E071875F48c41d75bE264700594d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm - xMSHARE',
    earnedTokenAddress: '0x9c7B23FD57a9a6A1E6205E82D7d3266cFB37C921',
    earnContractAddress: '0x9c7B23FD57a9a6A1E6205E82D7d3266cFB37C921',
    pricePerFullShare: 1,
    withdrawalFee: '2%',
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'xMSHARE',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'MagikSwap',
    assets: ['xMSHARE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    buyTokenUrl: 'https://magik.finance/xmshare',
  },
  {
    id: 'magikswap-magik-usdc',
    name: 'MS-MAGIK-USDC LP🔥',
    token: 'MS-MAGIK-USDC LP',
    tokenDescription: 'MagikSwap LP - AC Auto Burn Vault 🔥',
    tokenAddress: '0x2E28aEd21143CDAc666633bf2C31Db3F50E21EDD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-MS-MAGIK-USDC',
    earnedTokenAddress: '0x2901C550Eb1C333edB4Bd22B50b2688A505d6245',
    earnContractAddress: '0x2901C550Eb1C333edB4Bd22B50b2688A505d6245',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'magikswap-magik-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'MagikSwap',
    assets: ['MAGIK', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://magikswap.dog/liquidity',
    buyTokenUrl: 'https://magikswap.dog/swap',
  },
  {
    id: 'magikswap-mshare-usdc',
    name: 'MS-MSHARE-USDC LP🔥',
    token: 'MS-MSHARE-USDC LP',
    tokenDescription: 'MagikSwap LP - AC Auto Burn Vault 🔥',
    tokenAddress: '0xB1a53D68d55efB93a30D135A450c0a3cee864c25',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-MS-MSHARE-USDC',
    earnedTokenAddress: '0x720Ee1f99eAB68ffB5182510EBC66c365BfD442b',
    earnContractAddress: '0x720Ee1f99eAB68ffB5182510EBC66c365BfD442b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'magikswap-mshare-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'MagikSwap',
    assets: ['MSHARE', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://magikswap.dog/liquidity',
    buyTokenUrl: 'https://magikswap.dog/swap',
  },
  {
    id: 'magikswap-ftm-magik',
    name: 'MS-MAGIK-FTM LP🔥',
    token: 'MS-MAGIK-FTM LP',
    tokenDescription: 'MagikSwap LP - AC Auto Burn Vault 🔥',
    tokenAddress: '0xA4188C6ec7CeCDdC0D975c79E0430786fF6eDD07',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-MS-MAGIK-FTM',
    earnedTokenAddress: '0x14287606Db9C56f09AFb608732733f5358aF4Ed2',
    earnContractAddress: '0x14287606Db9C56f09AFb608732733f5358aF4Ed2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'magikswap-ftm-magik',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'MagikSwap',
    assets: ['MAGIK', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://magikswap.dog/liquidity',
    buyTokenUrl: 'https://magikswap.dog/swap',
  },
  {
    id: 'magikswap-ftm-mshare',
    name: 'MS-MSHARE-FTM LP🔥',
    token: 'MS-MSHARE-FTM LP',
    tokenDescription: 'MagikSwap LP - AC Vault',
    tokenAddress: '0xBeEe9C098E734E68054ab13Bbe6c43D4F1529E13',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-MS-MSHARE-FTM',
    earnedTokenAddress: '0xc5e00E5298DC32E2edFfeb09218aA3d4E279cB1c',
    earnContractAddress: '0xc5e00E5298DC32E2edFfeb09218aA3d4E279cB1c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'magikswap-ftm-mshare',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'MagikSwap',
    assets: ['MSHARE', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://magikswap.dog/liquidity',
    buyTokenUrl: 'https://magikswap.dog/swap',
  },
  {
    id: 'magikswap-mshare-magik',
    name: 'MS-MAGIK-MSHARE LP🔥',
    token: 'MS-MAGIK-MSHARE LP',
    tokenDescription: 'MagikSwap LP - AC Auto Burn Vault 🔥',
    tokenAddress: '0x451A339E0b122edDa68F750e5b1458Ec2b0935f7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-MS-MAGIK-MSHARE',
    earnedTokenAddress: '0x0A3DBdA327c4f82c551072081a4f3b3f2BF2b614',
    earnContractAddress: '0x0A3DBdA327c4f82c551072081a4f3b3f2BF2b614',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'magikswap-mshare-magik',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'eol',
    platform: 'MagikSwap',
    assets: ['MSHARE', 'MAGIK'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://magikswap.dog/liquidity',
    buyTokenUrl: 'https://magikswap.dog/swap',
  },
  {
    id: 'magikswap-mim-magik',
    name: 'MS-MAGIK-MIM LP🔥',
    token: 'MS-MAGIK-MIM LP',
    tokenDescription: 'MagikSwap LP - AC Auto Burn Vault 🔥',
    tokenAddress: '0x221f7e9a9561aC9193D4A79FE66072D5603223E8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm MAGIK-MIM',
    earnedTokenAddress: '0x18992Aebd4209392c21DBB6119c4F5eFB67872ae',
    earnContractAddress: '0x18992Aebd4209392c21DBB6119c4F5eFB67872ae',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'magikswap-mim-magik',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'MagikSwap',
    assets: ['MIM', 'MAGIK'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://magikswap.dog/liquidity',
    buyTokenUrl: 'https://magikswap.dog/swap',
  },
  {
    id: 'magikswap-mim-mshare',
    name: 'MS-MSHARE-MIM LP🔥',
    token: 'MS-MSHARE-MIM LP',
    tokenDescription: 'MagikSwap LP - AC Auto Burn Vault 🔥',
    tokenAddress: '0x6B9506E7811FfE2a1E860f0Bf2B9C7D78c5F5c22',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm MSHARE-MIM',
    earnedTokenAddress: '0x856fEc3B3A3a75bB8eC7FCC0a1b1202bf4FC65e3',
    earnContractAddress: '0x856fEc3B3A3a75bB8eC7FCC0a1b1202bf4FC65e3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'magikswap-mim-mshare',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'MagikSwap',
    assets: ['MIM', 'MSHARE'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://magikswap.dog/liquidity',
    buyTokenUrl: 'https://magikswap.dog/swap',
  },
  {
    id: 'lif3-tomb',
    name: 'TOMB LIF3 GP',
    logo: 'single-assets/TOMB.png',
    token: 'TOMB',
    tokenDescription: ' 1% Deposit Fee Tomb.Finance - Single Stake TOMB LIF3 GP Pool 🔥',
    tokenAddress: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm TOMB',
    earnedTokenAddress: '0xe1198C30021dD700A37C098b2e6a22cdF38A70e4',
    earnContractAddress: '0xe1198C30021dD700A37C098b2e6a22cdF38A70e4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'TOMB',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'TombSwap',
    assets: ['TOMB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?outputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
  },
  {
    id: 'spirit-ftm-magik',
    name: 'MAGIK-FTM LP🔥',
    token: 'MAGIK-FTM LP',
    tokenDescription: 'Magik Finance - AC Auto Burn Vault 🔥',
    tokenAddress: '0xDc71A6160322ad78DaB0abb47C7A581cFE9709Ee',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MAGIKfarm MAGIK-FTM',
    earnedTokenAddress: '0xe8cE63e37bB4B662837144058c298C4e7Fd0b93C',
    earnContractAddress: '0xe8cE63e37bB4B662837144058c298C4e7Fd0b93C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-magik',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['MAGIK', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x87a5C9B60A3aaf1064006FE64285018e50e0d020',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0x87a5c9b60a3aaf1064006fe64285018e50e0d020',
  },
  {
    id: 'spirit-ftm-mshare',
    name: 'MSHARE-FTM LP',
    token: 'MSHARE-FTM LP',
    tokenDescription: 'Magik.Finance - MSHARE - FTM LP',
    tokenAddress: '0x392C85cECcf9855986b0044a365A5532aeC6Fa31',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'magikfarmMSHARE-FTM',
    earnedTokenAddress: '0x0421D1E6F9728Da90a39c579F4b151198Df43DfB',
    earnContractAddress: '0x0421D1E6F9728Da90a39c579F4b151198Df43DfB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-mshare',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['MSHARE', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xC8Ca9026Ad0882133Ef126824F6852567c571A4E',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0xc8ca9026ad0882133ef126824f6852567c571a4e',
  },
  {
    id: 'spirit-mshare-magik',
    name: 'MSHARE-MAGIK LP',
    token: 'MSHARE-MAGIK LP',
    tokenDescription: 'Magik.Finance - MSHARE-MAGIK LP',
    tokenAddress: '0x4d6b28441c8B293A603243431E6E31F3C2632ddD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'magikfarmMSHARE-MAGIK',
    earnedTokenAddress: '0xc948Da8Eae9F65D7B6fD03658f1488a619DBB757',
    earnContractAddress: '0xc948Da8Eae9F65D7B6fD03658f1488a619DBB757',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-mshare-magik',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'eol',
    platform: 'SpiritSwap',
    assets: ['MSHARE', 'MAGIK'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x87a5C9B60A3aaf1064006FE64285018e50e0d020/0xC8Ca9026Ad0882133Ef126824F6852567c571A4E',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/MAGIK/0xC8Ca9026Ad0882133Ef126824F6852567c571A4E',
  },
  // {
  //   id: 'spooky-wftm-bshare',
  //   name: 'BSHARE - FTM LP',
  //   token: 'BSHARE - FTM LP',
  //   tokenDescription: 'Basedfinance.io - BSHARE - FTM LP AC Vault',
  //   tokenAddress: '0x6F607443DC307DCBe570D0ecFf79d65838630B56',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'MAGIKfarm MAGIK-FTM',
  //   earnedTokenAddress: '0xe8cE63e37bB4B662837144058c298C4e7Fd0b93C',
  //   earnContractAddress: '0xe8cE63e37bB4B662837144058c298C4e7Fd0b93C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'spooky-wftm-bshare',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   partnership: true,
  //   isMooStaked: true,
  //   status: 'active',
  //   platform: 'SpookySwap',
  //   assets: ['BASED', 'TOMB'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://swap.spiritswap.finance/#/add/FTM/0x87a5C9B60A3aaf1064006FE64285018e50e0d020',
  //   buyTokenUrl:
  //     'https://swap.spiritswap.finance/#/exchange/swap/FTM/0x87a5c9b60a3aaf1064006fe64285018e50e0d020',
  // },
  {
    id: 'spooky-eth-serene',
    name: 'Spooky - SRNv2-ETH LP',
    token: 'SRNv2-ETH LP',
    tokenDescription: '1% WITHDRAWAL FEE - app.serenitycapital.io - SERENE-ETH LP - AC Vault',
    tokenAddress: '0x25A5dd853220e5AF4407843f073a7a0A0e825b23',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-SP-SERENEV2-wETH',
    earnedTokenAddress: '0x087D8b7196CF8C136BcceEF5AD17202f685174F5',
    earnContractAddress: '0x087D8b7196CF8C136BcceEF5AD17202f685174F5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spooky-eth-serene',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'eol',
    platform: 'Spooky',
    assets: ['SRN', 'WETH'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x74b23882a30290451A17c44f4F05243b6b58C76d/0x60d8D17D6b824E19f77eACcAf16ED7BA6FB209C2',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d&outputCurrency=0x60d8D17D6b824E19f77eACcAf16ED7BA6FB209C2',
  },
  {
    id: 'spooky-eth-pebble',
    name: 'Spooky PEBBLE-ETH LP',
    token: 'PEBBLE-ETH LP',
    tokenDescription: '1% WITHDRAWAL FEE - app.serenitycapital.io - PEBBLE-ETH LP - AC Vault',
    tokenAddress: '0xc064a5d9Acba5741cFF44016B1fAA6207B21AEF6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-SP-PBL-ETH',
    earnedTokenAddress: '0x099Afa97546750Cb1e163EF6CEbD51657246722a',
    earnContractAddress: '0x099Afa97546750Cb1e163EF6CEbD51657246722a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spooky-eth-pebble',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'eol',
    platform: 'Spooky',
    assets: ['PBL', 'WETH'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x74b23882a30290451A17c44f4F05243b6b58C76d/0x9a2d0935E6CC558aaBa4EbD280F03A74b4752ADd',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d&outputCurrency=0x9a2d0935E6CC558aaBa4EbD280F03A74b4752ADd',
  },
  {
    id: 'spooky-degen',
    logo: 'single-assets/DEGEN.png',
    name: 'DEGEN🔥',
    token: 'DEGEN🔥',
    tokenDescription: 'degenfinance.us - Single Stake AC Auto Burn Vault🔥',
    tokenAddress: '0xF61d81d623d9c4a45ff5766EDa5AF224c3dde1A5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-DEGEN Single Stake',
    earnedTokenAddress: '0x5EB2d9f494098e8B470d045AF3bbE0c7F6ec8f41',
    earnContractAddress: '0x5EB2d9f494098e8B470d045AF3bbE0c7F6ec8f41',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DEGEN',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['DEGEN'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0xF61d81d623d9c4a45ff5766EDa5AF224c3dde1A5&inputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
  },
  {
    id: 'spooky-tomb-degen',
    name: 'DEGEN - TOMB LP 🔥',
    token: 'DEGEN - TOMB LP 🔥',
    tokenDescription: 'degenfinance.us - DEGEN-TOMB LP - AC Auto Burn Vault',
    tokenAddress: '0xF6b99c9B6E6bDbFd1B2De21F908189b49F43B9E3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-DEGEN-TOMB',
    earnedTokenAddress: '0xFF33a6d82436ACe973c2636822CeCd27e846b4C9',
    earnContractAddress: '0xFF33a6d82436ACe973c2636822CeCd27e846b4C9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spooky-tomb-degen',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['DEGEN', 'TOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', '5AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x6c021ae822bea943b2e66552bde1d2696a53fbb7/0xF61d81d623d9c4a45ff5766EDa5AF224c3dde1A5',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0xF61d81d623d9c4a45ff5766EDa5AF224c3dde1A5&inputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
  },
  {
    id: 'spooky-tomb-dshare',
    name: 'DSHARE - USDC LP',
    token: 'DSHARE - USDC LP',
    tokenDescription: 'degenfinance.us - DSHARE - USDC LP - AC Vault',
    tokenAddress: '0x1b622bB79d25f67e90BD189B5d116e0923Eb4ECB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-DSHARE-USDC',
    earnedTokenAddress: '0xB7C08E0Ca354657B50E923A1408fD2b7635CA638',
    earnContractAddress: '0xB7C08E0Ca354657B50E923A1408fD2b7635CA638',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spooky-tomb-dshare',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['DSHARE', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', '5AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x6E209329A33a63C463dbb65AE2d6655Fe5C98411',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x6E209329A33a63C463dbb65AE2d6655Fe5C98411&inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
  },
  // {
  //   id: 'spooky-usdc-mshare',
  //   name: 'MSHARE - USDC LP 🔥',
  //   token: 'MSHARE - USDC LP 🔥',
  //   tokenDescription: 'app.mvfinance.club - MSHARE - USDC LP - AC Fee Share Vault 50%',
  //   tokenAddress: '0x92A7b2A9ca7D70573E3a0B0BF9e5232c70db8a89',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'MagikFarm-MVMSHARE-USDC',
  //   earnedTokenAddress: '0xb9aC31D4AdA868EE8AF3b755D6b3c0d9DdFf2Ab9',
  //   earnContractAddress: '0xb9aC31D4AdA868EE8AF3b755D6b3c0d9DdFf2Ab9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'spooky-usdc-mshare',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   partnership: true,
  //   isMooStaked: true,
  //   status: 'active',
  //   platform: 'SpookySwap',
  //   assets: ['MSHAREMV', 'USDC'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', '5AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://spookyswap.finance/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0xb011EC534d9175cD7a69aFBfc1bcc9990862c462',
  //   buyTokenUrl:
  //     'https://spookyswap.finance/swap?outputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75&inputCurrency=0xb011EC534d9175cD7a69aFBfc1bcc9990862c462',
  // },
  {
    id: 'spooky-usdc-mvdollar',
    name: 'MVDOLLAR-USDC🔥',
    token: 'MVDOLLAR-USDC 🔥',
    tokenDescription: 'app.mvfinance.club - MVDOLLAR-USDC - AC Fee Share Vault 50%',
    tokenAddress: '0x35bED1E2f3033395a05CD0b1b5900209ECe42774',
    tokenDecimals: 18,
    tokenDescriptionUrl: '50% of performance rewards are sent back to the Miniverse Team',
    earnedToken: 'MagikFarm-MVDOLLAR-USDC',
    earnedTokenAddress: '0x8aA7cE2451D8ab2Ea4B090620ccb1fF67681d676',
    earnContractAddress: '0x8aA7cE2451D8ab2Ea4B090620ccb1fF67681d676',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spooky-usdc-mvdollar',
    oraclePrice: 0,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['MVDOLLAR', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', '5AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x57976c467608983513c9355238dc6de1B1aBbcCA',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x57976c467608983513c9355238dc6de1B1aBbcCA&inputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75',
  },
  {
    id: 'spooky-mvdollar',
    logo: 'single-assets/MVDOLLAR.png',
    name: 'MVDOLLAR',
    token: 'MVDOLLAR',
    tokenDescription: 'app.mvfinance.club - MVDOLLAR Single Stake - AC Vault',
    tokenAddress: '0x57976c467608983513c9355238dc6de1B1aBbcCA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-MVDOLLAR-Single Stake',
    earnedTokenAddress: '0x5fa6B2c4A999331484a16C865BCA1aBdeD4CF922',
    earnContractAddress: '0x5fa6B2c4A999331484a16C865BCA1aBdeD4CF922',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'MVDOLLAR',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['MVDOLLAR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x57976c467608983513c9355238dc6de1B1aBbcCA&inputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75',
  },
  {
    id: 'red2omb-2omb-ftm',
    name: 'RED 2OMB-FTM🔥',
    token: 'RED 2OMB-FTM🔥',
    tokenDescription: '2omb.finance Redemption.fi LP - AC Auto Burn Vault',
    tokenAddress: '0x3B602C7Ed7F9318926Ffbf767E5dc838cA210085',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-Redemption-2omb-FTM ',
    earnedTokenAddress: '0xe9f7F029dd4107f481A424552A4A8e04676D86A1',
    earnContractAddress: '0xe9f7F029dd4107f481A424552A4A8e04676D86A1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'red2omb-2omb-ftm',
    oraclePrice: 0,
    retired: true,
    depositsPaused: true,
    partnership: true,
    isMooStaked: true,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['2OMB', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', '5AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.redemption.fi/liquidity/forge/0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE/ETH',
    buyTokenUrl:
      'https://app.redemption.fi/swap?outputCurrency=0x9b7F7E44904Bb61a9710685c938eA5f867Cb36b2',
  },

  {
    id: '2omb-2omb-ftm',
    name: 'Spooky 2OMB-FTM🔥',
    token: 'Spooky 2OMB-FTM🔥',
    tokenDescription: '2omb.finance Spooky LP - AC Auto Burn Vault',
    tokenAddress: '0xbdC7DFb7B88183e87f003ca6B5a2F81202343478',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MagikFarm-Spooky-2omb-FTM',
    earnedTokenAddress: '0xa64aBA2Ed2E6dDb861f901329420e04F6493DED4',
    earnContractAddress: '0xa64aBA2Ed2E6dDb861f901329420e04F6493DED4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: '2omb-2omb-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    retireReason: 'rewards',
    partnership: true,
    isMooStaked: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['2OMB', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', '5AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE/FTM',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x9b7F7E44904Bb61a9710685c938eA5f867Cb36b2',
  },
];
